<template>
  <v-navigation-drawer
    :value="isAddNewBillingCredentialsSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 600 : '100%'"
    app
    @input="val => $emit('update:is-add-new-billing-credentials-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Billing Credentials</span>
        <v-spacer></v-spacer>
        <v-btn icon small
               @click="$emit('update:is-add-new-billing-credentials-sidebar-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="billingCredentialsForm" @submit.prevent="onSubmit">
          <v-text-field
            :rules="[validators.required]"
            v-model="billingCredentialsData.name"
            outlined
            dense
            label="Name"
            id="name"
            placeholder="Wire Transfer PLN"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <v-select
            v-model="billingCredentialsData.currency"
            :rules="[validators.required]"
            label="Currency"
            :items="currencies"
            id="currency"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          ></v-select>
          <v-checkbox
            label="Default"
            hide-details="auto"
            id="default-checkbox"
            dense
            class="mb-6"
            v-model="billingCredentialsData.isDefault"
          ></v-checkbox>
          Rows:
            <v-row v-for="(item, index) in billingCredentialsData.items" :key="index" :class="`billing-credentials-data data-${index} mt-0`">
              <v-col cols="5">
                <v-text-field
                  :rules="[validators.required]"
                  v-model="item.label"
                  outlined
                  dense
                  label="Label"
                  data-input-value="label"
                  placeholder="Account Number"
                  hide-details="auto"
                  class="mb-5"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  :rules="[validators.required]"
                  v-model="item.value"
                  outlined
                  dense
                  label="Value"
                  data-input-value="value"
                  placeholder="111222333"
                  hide-details="auto"
                  class="mb-5"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-btn @click="removeBillingCredentialItem(index)" data-action="remove" icon block tile color="red">
                  <v-icon>{{ icons.mdiMinus }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="2" offset="10">
                <v-btn @click="addBillingCredentialItem" data-action="add" icon block tile color="primary">
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetBillingCredentialsForm"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiPlus, mdiMinus } from '@mdi/js';
import { required } from '@core/utils/validation';
import useBillingCredentials from './useBillingCredentials';
import Currency from '@/enums/currency.enum';

export default {
  name: 'BillingCredentialsAddNew',
  model: {
    prop: 'isAddNewBillingCredentialsSidebarActive',
    event: 'update:is-add-new-billing-credentials-sidebar-active',
  },
  props: {
    isAddNewBillingCredentialsSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      addBillingCredentialItem,
      billingCredentialsForm,
      billingCredentialsData,
      resetBillingCredentialsForm,
      removeBillingCredentialItem,
      onSubmit,
    } = useBillingCredentials(emit);

    return {
      addBillingCredentialItem,
      billingCredentialsForm,
      resetBillingCredentialsForm,
      removeBillingCredentialItem,
      validators: { required },
      currencies: Object.values(Currency),
      billingCredentialsData,
      onSubmit,
      icons: {
        mdiClose,
        mdiPlus,
        mdiMinus,
      },
    };
  },
};
</script>
