import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import Currency from '@/enums/currency.enum';
import handleError from '@/plugins/handle-error';
import Vue from 'vue';

export default function useBillingCredentials(emit) {
  const billingCredentialsListTable = ref([]);

  const tableColumns = [
    { text: 'Name', value: 'name', sortable: false },
    { text: 'Currency', value: 'currency', sortable: false },
    { text: 'Default', value: 'isDefault', sortable: false },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ];

  const isAddNewBillingCredentialsSidebarActive = ref(false);
  const isBillingCredentialsEditDialogOpen = ref(false);
  const billingCredentialsForm = ref(null);
  const errorMessages = ref({});
  const totalBillingCredentialsListTable = ref(0);
  const loading = ref(false);
  const selectedRows = ref([]);
  const selectedBillingCredentials = ref(null);
  const options = ref({});

  const fetchBillingCredentials = () => {
    store.dispatch('billingCredentials/fetchBillingCredentials', {
      page: options.value.page,
      itemsPerPage: options.value.itemsPerPage,
    })
    .then(response => {
      billingCredentialsListTable.value = response.data;
      totalBillingCredentialsListTable.value = response.totalCount;
      loading.value = false;
    })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Billing Credentials',
          text: 'Cannot fetch billing credentials',
        });
      });
  };

  watch([options], () => {
    loading.value = true;
    selectedRows.value = [];
    fetchBillingCredentials();
  });

  const blankBillingCredentialsData = {
    name: '',
    currency: Currency.EUR,
    isDefault: false,
    items: []
  };

  const billingCredentialsData = ref(JSON.parse(JSON.stringify(blankBillingCredentialsData)));
  const resetBillingCredentialsData = () => {
    billingCredentialsData.value = JSON.parse(JSON.stringify(blankBillingCredentialsData));
    emit('update:is-add-new-billing-credentials-sidebar-active', false);
  };

  const resetBillingCredentialsForm = () => {
    billingCredentialsData.value = JSON.parse(JSON.stringify(blankBillingCredentialsData));
    resetForm();
    emit('update:is-add-new-billing-credentials-sidebar-active', false);
  };

  const addBillingCredentialItem = () => {
    billingCredentialsData.value.items.push({
      label: null,
      value: null,
    });
  };

  const removeBillingCredentialItem = (itemIndex) => {
    billingCredentialsData.value.items.splice(itemIndex, 1);
  };

  const resetForm = () => {
    billingCredentialsForm.value.reset();
  };

  const onSubmit = () => {
    const isFormValid = billingCredentialsForm.value.validate();

    if (!isFormValid) {
      return;
    }

    store
      .dispatch('billingCredentials/saveBillingCredentials', billingCredentialsData.value)
      .then(() => {
        emit('refetch-data');
        emit('update:is-add-new-billing-credentials-sidebar-active', false);
        resetBillingCredentialsData();
      })
      .catch(error => (errorMessages.value = handleError(error)));
  };

  return {
    addBillingCredentialItem,
    isAddNewBillingCredentialsSidebarActive,
    isBillingCredentialsEditDialogOpen,
    billingCredentialsForm,
    fetchBillingCredentials,
    resetBillingCredentialsForm,
    removeBillingCredentialItem,
    billingCredentialsData,
    onSubmit,
    options,
    selectedRows,
    selectedBillingCredentials,
    tableColumns,
    billingCredentialsListTable,
    totalBillingCredentialsListTable,
    loading,
  };
}
