var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"billing-credentials-list"}},[_c('billing-credentials-add-new',{on:{"refetch-data":_vm.fetchBillingCredentials},model:{value:(_vm.isAddNewBillingCredentialsSidebarActive),callback:function ($$v) {_vm.isAddNewBillingCredentialsSidebarActive=$$v},expression:"isAddNewBillingCredentialsSidebarActive"}}),_c('billing-credentials-edit',{attrs:{"is-billing-credentials-edit-dialog-open":_vm.isBillingCredentialsEditDialogOpen,"billing-credentials-data":_vm.selectedBillingCredentials},on:{"update:isBillingCredentialsEditDialogOpen":function($event){_vm.isBillingCredentialsEditDialogOpen=$event},"update:is-billing-credentials-edit-dialog-open":function($event){_vm.isBillingCredentialsEditDialogOpen=$event},"refetch-data":_vm.fetchBillingCredentials}}),_c('v-card',[_c('v-card-title',[_vm._v(" Billing Credentials ")]),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"add-new-billing-credentials mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.isAddNewBillingCredentialsSidebarActive = !_vm.isAddNewBillingCredentialsSidebarActive}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Add")])],1)],1)],1),_c('v-data-table',{attrs:{"options":_vm.options,"headers":_vm.tableColumns,"items":_vm.billingCredentialsListTable,"item-class":_vm.billingCredentialsItemClass,"server-items-length":_vm.totalBillingCredentialsListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"billing-credentials-item-name",attrs:{"data-id":item.id}},[_vm._v(_vm._s(item.name))])]}},{key:"item.isDefault",fn:function(ref){
var item = ref.item;
return [(item.isDefault)?_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCheck))]):_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiMinus))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"billing-credentials-list-item-edit",on:{"click":function($event){_vm.selectedBillingCredentials = item
                _vm.isBillingCredentialsEditDialogOpen = !_vm.isBillingCredentialsEditDialogOpen}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Edit")])],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }