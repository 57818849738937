<template>
  <div id="billing-credentials-list">
    <billing-credentials-add-new
      v-model="isAddNewBillingCredentialsSidebarActive"
      @refetch-data="fetchBillingCredentials"
    ></billing-credentials-add-new>
    <billing-credentials-edit
      :is-billing-credentials-edit-dialog-open.sync="isBillingCredentialsEditDialogOpen"
      :billing-credentials-data="selectedBillingCredentials"
      @refetch-data="fetchBillingCredentials"
    ></billing-credentials-edit>
    <v-card>
      <v-card-title> Billing Credentials </v-card-title>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="add-new-billing-credentials mb-4 me-3"
            @click.stop="isAddNewBillingCredentialsSidebarActive = !isAddNewBillingCredentialsSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :options.sync="options"
        :headers="tableColumns"
        :items="billingCredentialsListTable"
        :item-class="billingCredentialsItemClass"
        :server-items-length="totalBillingCredentialsListTable"
        :loading="loading"
      >
        <template #[`item.name`]="{ item }">
          <span class="billing-credentials-item-name" :data-id="item.id">{{ item.name }}</span>
        </template>

        <template #[`item.isDefault`]="{ item }">
          <v-icon v-if="item.isDefault">{{ icons.mdiCheck }}</v-icon>
          <v-icon v-else>{{ icons.mdiMinus }}</v-icon>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item class="billing-credentials-list-item-edit" @click="
                  selectedBillingCredentials = item
                  isBillingCredentialsEditDialogOpen = !isBillingCredentialsEditDialogOpen
                ">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import useBillingCredentials from './useBillingCredentials';
import BillingCredentialsAddNew from './BillingCredentialsAddNew';
import BillingCredentialsEdit from './BillingCredentialsEdit';
import { mdiPlus, mdiDotsVertical, mdiFileDocumentOutline, mdiDeleteOutline, mdiCheck, mdiMinus } from '@mdi/js';

export default {
  name: 'BillingCredentials',
  components: {
    BillingCredentialsAddNew,
    BillingCredentialsEdit,
  },
  setup() {
    const {
      isAddNewBillingCredentialsSidebarActive,
      isBillingCredentialsEditDialogOpen,
      fetchBillingCredentials,
      selectedRows,
      selectedBillingCredentials,
      options,
      tableColumns,
      billingCredentialsListTable,
      totalBillingCredentialsListTable,
      loading,
    } = useBillingCredentials();

    const billingCredentialsItemClass = () => {
      return 'billing-credentials-item';
    };

    return {
      isAddNewBillingCredentialsSidebarActive,
      isBillingCredentialsEditDialogOpen,
      fetchBillingCredentials,
      selectedRows,
      selectedBillingCredentials,
      options,
      tableColumns,
      billingCredentialsListTable,
      billingCredentialsItemClass,
      totalBillingCredentialsListTable,
      loading,
      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiDeleteOutline,
        mdiCheck,
        mdiMinus
      }
    };
  },
};
</script>
