<template>
  <v-dialog
    v-model="isBillingCredentialsEditDialogOpen"
    max-width="650px"
    @click:outside="$emit('update:is-billing-credentials-edit-dialog-open', false)"
  >
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Edit Billing Credentials </v-card-title>

      <v-card-text v-if="billingCredentialsDataLocal" class="mt-5">
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                :rules="[validators.required]"
                v-model="billingCredentialsDataLocal.name"
                outlined
                dense
                label="Name"
                placeholder="Wire Transfer PLN"
                hide-details="auto"
                class="mb-6"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-select
                v-model="billingCredentialsDataLocal.currency"
                :rules="[validators.required]"
                label="Currency"
                :items="currencies"
                outlined
                dense
                hide-details="auto"
                class="mb-6"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3" class="py-0">
              <v-checkbox
                label="Default"
                hide-details="auto"
                dense
                class="mb-6"
                v-model="billingCredentialsDataLocal.isDefault"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <span>Rows:</span>
            </v-col>
          </v-row>
          <v-row v-for="(item, index) in billingCredentialsDataLocal.items" :key="index">
            <v-col cols="5" class="py-0">
              <v-text-field
                :rules="[validators.required]"
                v-model="item.label"
                outlined
                dense
                label="Label"
                placeholder="Account Number"
                hide-details="auto"
                class="mb-5"
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="py-0">
              <v-text-field
                :rules="[validators.required]"
                v-model="item.value"
                outlined
                dense
                label="Value"
                placeholder="111222333"
                hide-details="auto"
                class="mb-5"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="py-0">
              <v-btn @click="removeBillingCredentialItem(index)" icon block tile color="red">
                <v-icon>{{ icons.mdiMinus }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2" offset="10" class="py-0">
              <v-btn @click="addBillingCredentialItem" icon block tile color="primary">
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> submit </v-btn>
              <v-btn outlined color="secondary" @click.prevent="$emit('update:is-billing-credentials-edit-dialog-open', false)">
                Discard
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import { required } from '@core/utils/validation';
import Vue from 'vue';
import Currency from '@/enums/currency.enum';
import { mdiMinus, mdiPlus } from '@mdi/js';

export default {
  props: {
    isBillingCredentialsEditDialogOpen: {
      type: Boolean,
      required: true,
    },
    billingCredentialsData: {
      required: false,
    },
  },
  setup(props, { emit }) {
    const billingCredentialsDataLocal = ref({});
    billingCredentialsDataLocal.value = JSON.parse(JSON.stringify(props.billingCredentialsData));

    const valid = ref(false);
    const form = ref(null);

    const validate = () => {
      form.value.validate();
    };

    const addBillingCredentialItem = () => {
      billingCredentialsDataLocal.value.items.push({
        label: null,
        value: null,
      });
    };

    const removeBillingCredentialItem = (itemIndex) => {
      billingCredentialsDataLocal.value.items.splice(itemIndex, 1);
    };

    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('billingCredentials/updateBillingCredentials',
            {
              id: props.billingCredentialsData.id,
              billingCredentials: billingCredentialsDataLocal.value
            }
          )
          .then(() => {
            emit('refetch-data');
            emit('update:is-billing-credentials-edit-dialog-open', false);
          })
          .catch(e => {
            Vue.notify({
              type: 'error',
              title: 'Billing Credentials',
              text: e.response.data.message,
              duration: 5000,
            });
          });
      } else {
        validate();
      }
    };

    watch(
      () => props.isBillingCredentialsEditDialogOpen,
      () => {
        billingCredentialsDataLocal.value = JSON.parse(JSON.stringify(props.billingCredentialsData));
      },
    );

    return {
      form,
      billingCredentialsDataLocal,
      addBillingCredentialItem,
      removeBillingCredentialItem,
      currencies: Object.values(Currency),
      onSubmit,
      valid,
      validate,
      validators: { required },
      icons: {
        mdiPlus,
        mdiMinus,
      },
    };
  },
};
</script>
